import axios from 'axios';
import Swal from 'sweetalert2';
import authHelper, { getMtaApAuthData } from './authHelper';
import { apiBaseUrl } from './config';
import { Toast } from './utility';

export function axiosErrorHandler(error){
    console.log(error);
    if (error?.response?.status === 401) {

        authHelper.validate(true);
    }
    
    if(error?.code ==='ERR_NETWORK'){
      Toast.fire({
        icon: 'error',
        title: error?.message
    });
    }
    if (error?.response?.status === 422){
      const data=error.response.data;
  
      Swal.fire({
        icon: 'error',
        title: 'Unprocessable Entity',
        text: `${JSON.stringify(data.errors)}`
      });
    }
  }

  export function axiosResponseHandler(response){
    const res = response.data;
    if(res.status ==='error'){
        Toast.fire({
            icon: 'error',
            title: res.msg
        });

        return false;
    }
  }

  
 export const callTheApServerGet  = axios.create({
    method: 'get',
    baseURL: apiBaseUrl,
    timeout: 60*1000,
    headers: { Authorization: `Bearer ${getMtaApAuthData().accessToken}` }
  });

  export const callTheApServerPost  = axios.create({
    method: 'post',
    baseURL: apiBaseUrl,
    timeout: 60*1000,
    headers: { Authorization: `Bearer ${getMtaApAuthData().accessToken}` }
  });