import axios from 'axios';
import store from 'store';
import Swal from 'sweetalert2';
import { apiBaseUrl } from './config';
import { unixTimeStampInSeconds } from './utility';

export const getMtaApAuthData = () =>{
    let mtaApAuthData = store.get('mtaApAuthData');

    if(!mtaApAuthData){
        mtaApAuthData = {
            accessToken: undefined,
            refreshToken: undefined,
            updatedAt:undefined
        };
    }

   return  mtaApAuthData;
};
export const setMtaApAuthData = ( accessToken, refreshToken) =>{
    store.set('mtaApAuthData', {
        accessToken,
        refreshToken,
        updatedAt: unixTimeStampInSeconds()
    });
};

class authHelper {
    static validate = (forceStatus) => {
        try {
            const path = window.location.pathname;
            let page = path.split('/').pop();
            forceStatus = forceStatus || false;
            if(page ==='login'){
                page ='login.html';
              }else if(page ==='register'){
                page ='register.html';
              }
          
            // console.log(getMtaApAuthData());
            if (!getMtaApAuthData().refreshToken) {

                return location.replace('./login.html');
                // console.log('redirect to login');
            }

            let lastUpdatedDiff = unixTimeStampInSeconds() - getMtaApAuthData().updatedAt;
            if(forceStatus){
                lastUpdatedDiff = lastUpdatedDiff+ 60 * 8;
            }
            console.log(lastUpdatedDiff);
            let requiredReloadOnResult = false;

            
          




            if (lastUpdatedDiff > 60 * 8) {
                requiredReloadOnResult = true;

                //TODO: show sweet alert here 

                Swal.fire({
                    title: 'Validating Connection...',
                    showConfirmButton: false,


                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }

                });

                setTimeout(() => {
                    Swal.close();
                    location.replace('./login.html');
                }, 1000 * 60 * 2);

            }

            if (lastUpdatedDiff > 60 * 5) {
                // refresh the token 




                axios({
                    method: 'get',
                    url: apiBaseUrl + '/auth/refresh-token',
                    headers: { Authorization: `Bearer ${getMtaApAuthData().refreshToken}` }

                })
                    .then(function (response) {
                        console.log(response.data);

                        if (response.data.status === 'success') {
                        

                            const { accessToken} = response.data;
                            setMtaApAuthData( accessToken, getMtaApAuthData().refreshToken);


                            if (page ==='login.html' || page === 'register.html') {
                                location.replace('./index.html');
                            }

                            if (requiredReloadOnResult) {

                                location.reload();
                                // console.log('reloading page');
                            }

                        } else {
                            authHelper.logout();
                        }

                    }).catch(function (error) {
                        console.log(error);

                        if (error.response.status === 401) {

                            authHelper.logout();
                        }

                    });
            }
            if (lastUpdatedDiff < 60 * 5) {
                // console.log(page);
                if (page ==='login.html' || page === 'register.html') {
                    location.replace('./index.html');
                }
            }


            // adding event listener for logout button
            var elements = document.getElementsByClassName('logout-on-click');

            var myFunction = function () {
                authHelper.logout();
                // console.log('this . logout trigged');
            };

            for (var i = 0; i < elements.length; i++) {
                elements[i].addEventListener('click', myFunction, false);
            }

        } catch (error) {
            console.log(error);
        }


    };

    static logout = () => {
        store.remove('mtaApAuthData');
        this.validate();
    };

 
}



export default authHelper;