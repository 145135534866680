type serverOwnerType = 'client' | 'primexop';
export let isLocalEnvironment = true;
export let apiBaseUrl;
export let webApkBaseUrl;
export let newAdminPanelBaseUrl;

export let serverOwner: serverOwnerType = 'client';

switch (location.host) {
    case '192.168.100.5':
   
        break;
    case '127.0.0.1:5500':
        
        break;
    case '192.168.100.5:5502':
   
        break;

    default:

       
        isLocalEnvironment = false;
}

if (isLocalEnvironment === true) {
    // local links 
    apiBaseUrl = 'http://192.168.100.9:3005/adminPanel';
    webApkBaseUrl = 'http://localhost:3000';
    newAdminPanelBaseUrl = 'http://127.0.0.1:3002';

} else {
    // production links 
    apiBaseUrl = 'https://api.gamerheart.in/adminPanel';
    webApkBaseUrl = 'https://war.pxop.me';
    newAdminPanelBaseUrl = 'https://admin.gamerheart.in';
   
}